/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

export default function Policy() {

  return (
    <div className="container">
      <p style={{ textAlign: 'center', fontWeight: 700, fontSize: '25px',}}>
        <strong><span>QUY ĐỊNH SỬ DỤNG PHẦN MỀM</span></strong>
      </p>
      
      <p><strong><span>Cơ
      sở dữ liệu quản lý quy hoạch, cung cấp thông tin quy hoạch trực tuyến</span></strong></p>
      <p><strong><span>trên
      nền tảng hệ thống công nghệ thông tin địa lý toàn cầu, điện toán đám mây trên địa
      bàn tỉnh Phú Thọ</span></strong></p>
      <p><strong><span>1.Các thuật ngữ sử dụng trong thỏa thuận</span></strong></p>
      <p><span>Đồ án quy hoạch: Là tài liệu thể hiện nội dung của quy
      hoạch, bao gồm các bản vẽ, mô hình, thuyết minh và quy định quản lý theo quy định
      của nhà nước.</span></p>
      <p><span>Bản đồ quy hoạch hay sơ đồ quy hoạch là một bản vẽ chi
      tiết về nội dung quy hoạch </span></p>
      <p><span>Bản đồ số: Là bản đồ quy hoạch đã được số hóa (Chuyển
      đổi số) </span></p>
      <p><span>Chủ đầu tư: Sở xây dựng tỉnh Phú Thọ</span></p>
      <p><span>Người dùng: Là toàn bộ người dùng trên internet, được
      truy cập phần mềm, và sử dụng các chức năng công cộng của dự án.</span></p>
      <p><span>Quản trị viên: Là những người thuộc chủ đầu tư, được cấp
      quyền truy cập vào một số khu vực hạn chế truy cập của phần mềm, để thực hiện
      chức năng quản trị phần mềm.</span></p>
      <p><span>Chúng tôi: Sở xây dựng tỉnh Phú Thọ (Chủ đầu tư)</span></p>
      <p><strong><span>2. Sản phẩm phần mềm và đối tượng phục vụ</span></strong></p>
      <p><span>- Phần mềm được xây dựng để cung cấp thông tin quy hoạch
      đến người dùng, cung cấp công cụ quản trị cho các quản trị viên.</span></p>
      <p><span>- Phần mềm được xây dựng trên ba nền tảng: Website
      (WebGIS), Android, và iOS</span></p>
      <p><strong><span>3.Quy định về sử dụng phần mềm.</span></strong></p>
      <p><span>3.1 Phí khi sử dụng phần mềm.</span></p>
      <p><span>- Phần mềm cung cấp miễn phí thông tin quy hoạch trực
      tuyến, với các quy hoạch đã được các cấp có thẩm quyền phê duyệt, công bố, tới
      toàn bộ người dân.</span></p>
      <p><span>3.2 Những chức năng, tính năng, của phần mềm mà người
      dùng được sử dụng.</span></p>
      <p><span>- Người dùng được sử dụng tất cả các chức năng mà phần
      mềm không yêu cầu đăng ký tài khoản.</span></p>
      <p><span>- Người dùng được sử dụng tính năng xem hồ sơ quy hoạch,
      nhưng phải đăng ký tài khoản (miễn phí)</span></p>
      <p><span>3.2 Những chức năng, tính năng người dùng không được
      phép sử dụng.</span></p>
      <p><span>- Người dùng không được phép truy cập vào khu vực dành
      cho nhà quản trị.</span></p>
      <p><span>- Người dùng không được phép truy cập trái phép và các
      đường dẫn liên quan đến phần mềm, nhưng không được đặt công khai trên giao diện
      phần mềm.</span></p>
      <p><span>3.3 Tuân thủ pháp luật khi sử dụng phần mềm và các nội
      dung trên phần mềm.</span></p>
      <p><span>- Người dùng khi sử dụng phần mềm cơ sở dữ liệu quản
      lý quy hoạch, cung cấp thông tin quy hoạch trực tuyến<span>&nbsp; </span>trên nền tảng hệ thống công nghệ thông tin địa
      lý toàn cầu, điện toán đám mây<span>&nbsp; </span>trên địa
      bàn tỉnh Phú Thọ, phải tuyệt đối tuân thủ các quy định của pháp luật, về việc sử
      dụng tài nguyên trên internet và các quy định pháp luật liên quan khác.</span></p>
      <p><span>4. Bản quyền phần mềm và dữ liệu</span></p>
      <p><span>- Bản quyền phần mềm thuộc về: Sở Xây Dựng tỉnh Phú Thọ.</span></p>
      <p><span>5. Tạm ngừng, ngừng cung cấp dịch vụ</span></p>
      <p><span>Chủ đầu tư có quyền tạm ngừng, hoặc ngừng cung cấp dịch
      vụ mà không cần báo trước cho người dùng, bao gồm nhưng không giới hạn các trường
      hợp cụ thể sau:</span></p>
      <p><span>-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Bảo trì,
      nâng cấp phần mềm.</span></p>
      <p><span>-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Do thiên
      tai như: Động đất, sóng thần, dịch bệnh, ….</span></p>
      <p><span>-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Tạm
      ngưng dịch vụ vì các lý do khác.</span></p>
      <p><span>6. Đăng ký tài khoản, thu thập và sử dụng thông tin
      người dùng.</span></p>
      <p><span>- Để sử dụng các chức năng phần mềm trong mục: Quản lý
      hồ sơ quy hoạch (Xem hồ sơ quy hoạch, download hồ sơ quy hoạch, …) người dùng cần
      phải đăng ký thành viên.</span></p>
      <p><span>- Hệ thống thu thập các thông tin cơ bản của người
      dùng, để phục vụ cho việc đăng ký tài khoản, bao gồm: Họ và Tên, Email, Mobile
      Number</span></p>
      <p><span>- Việc đăng ký thành viên là hoàn toàn miễn phí, và bạn
      không phải trả bất cứ khoản phí nào khi sử dụng phần mềm.</span></p>
      <p><span>- Chúng tôi giữ bí mật thông tin tài khoản của người
      dùng.</span></p>
      <p></p>
      <p><span>* Chỉ sử dụng các thông tin này cho việc bạn đăng ký
      tài khoản, đăng nhập tài khoản trên phần mềm.</span></p>
      <p><span>* Không chia sẻ thông tin cá nhân của bạn với bất cứ
      bên thứ ba nào.</span></p>
      <p><span>* Không sử dụng thông tin tài khoản của bạn vào bất cứ
      mục đích sử dụng nào khác.</span></p>
    </div>
  );
}
