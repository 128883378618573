const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://thanhthuy-api.cgis.asia/",
    domainUserSide: "http://localhost:3000",
    domainAdminSide: "http://localhost:3001",
    domainName: "",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/thanhthuy/wms",
    datastore: "thanhthuy",
    gwcUrl: ''
  },
  production: {
    api: "https://thanhthuy-api.cgis.asia/",
    domainUserSide: "https://thanhthuy.cgis.asia",
    domainAdminSide: "https://admin.thanhthuy.cgis.asia",
    domainName: "thanhthuy.cgis.asia",
    doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
    doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/thanhthuy/wms",
    datastore: "thanhthuy",
    gwcUrl: ''
  },
};

module.exports = apiEnvironment[env];
