import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetLookupPlanningType = () => {
    return service
        .get(ApiUrl.GetLookupPlanningType)
        .then((res) => {
            return res;
          })
          .catch((err) => {
            throw err;
          });
}
