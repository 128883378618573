import { Map } from "immutable";
import { DecodeToken } from "./jwt-token-helper";
import Cookies from "universal-cookie";
import history from "../common/history";
import ShowNotification from "../components/react-notifications/react-notifications";
import viVN from "../languages/vi";
import * as apiConfig from "../api/api-config";

let configuration = Map();

export const DomainUserSide = apiConfig.domainUserSide;
export const DomainAdminSide = apiConfig.domainAdminSide;
const domainName = apiConfig.domainName;

export const DoMainNameGeoServer = apiConfig.doMainNameGeoServer;
export const DoMainNameGeoServerWMS = apiConfig.doMainNameGeoServerWMS;
export const DataStore = apiConfig.datastore;
export const gwcUrl = apiConfig.gwcUrl;

export const ApiServerKey = {
  APP_API_ROOT: "API_ROOT",
};

export const TokenPrefix = "Bearer";
export const APIUrlDefault = apiConfig.api;
export const GoogleMapAPI = "AIzaSyBpL4ImLERcE1UQswgIBTdYueGkO2Zm4mE";
export const libraries = ["places"];
export const region = "vi_VN";
export const language = "vi_VN";

// export const defaultMapCenter = [105.24536751196266,20.795117068833864];
// export const defaultLeftCornerLng = 102.58032372900223;
// export const defaultLeftCornerLat = 19.59980043987513;
// export const defaultRightCornerLng = 107.53451766286365;
// export const defaultRightCornerLat = 21.920610178540418;

export const defaultMapCenter = [105.22371220243771, 21.190422193885386];
export const defaultLeftCornerLng = 104.90925055905971;
export const defaultLeftCornerLat = 20.99284998866424;
export const defaultRightCornerLng = 105.5381738458157;
export const defaultRightCornerLat = 21.38799439910653;


//--- Cookies
const cookies = new Cookies();
const dateExpires = new Date();
dateExpires.setTime(dateExpires.getTime() + 720 * 60 * 60 * 1000);

export function setCookies(
  name,
  value,
  options = { path: "/", domain: domainName, expires: dateExpires }
) {
  cookies.set(name, value, options);
}

export function getCookies(name) {
  return cookies.get(name);
}

export function removeCookies(
  name,
  options = { path: "/", domain: domainName }
) {
  cookies.remove(name, options);
}

export function removeListCookies(nameList) {
  if (nameList instanceof Array) {
    nameList.map((name) => {
      cookies.remove(name, { path: "/", domain: domainName });
      cookies.remove(name, { path: "/", domain: window.location.host });
    });
  }
}

export function setConfiguration(name, value) {
  configuration = configuration.set(name, value);
}

export function getConfiguration(key) {
  if (!configuration.has(key)) {
    throw new Error("Undefined configuration key: " + key);
  }

  return configuration.get(key);
}

export function onRemoveTokens(tokens) {
  return Promise.resolve(onRemoveTokenKeys(tokens));
}

function onRemoveTokenKeys(tokens) {
  if (tokens && tokens.length > 0) {
    tokens.map((t) => localStorage.removeItem(t));
  }
}

export function getUserInfo() {
  let userInfoToken = getCookies(TokenKey.token);
  let userInfo = DecodeToken(userInfoToken);
  if (userInfo) {
    //console.log('user', userInfo);
    if(userInfo.userRole === "ADMIN") {
      setCookies("IS_ADMIN", true);
    }
    return userInfo;
  }

  return null;
}

export const NotificationMessageType = {
  Success: "success",
  Warning: "warning",
  Error: "error",
};

export const NotificationPosition = {
  TopLeft: "top-left",
  TopRight: "top-right",
  TopCenter: "top-center",
  Center: "center",
  BottomLeft: "bottom-left",
  BottomRight: "bottom-right",
  BottomCenter: "bottom-center",
};

export const TokenKey = {
  token: "token",
  refreshToken: "refreshToken",
  returnUrl: "returnUrl",
  DeviceId: "DeviceId"
};

export function changeAlias(alias) {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // str = str.replace(/ + /g, " ");
  str = str.toLowerCase();
  str = str.trim();
  return str;
}

export const toSlug = (str) => {
  if (typeof str !== 'string') {
    return ''
  }
  str = str.toLowerCase();
  str = str.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  str = str.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  str = str.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  str = str.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  str = str.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  str = str.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  str = str.replace(/đ/gi, 'd');
  str = str.replace(/[\W]/gi, ' ');
  str = str.trim();
  str = str.replace(/\s+/gi, '-');
  return str;
}

export function HandleError(error) {
  if (error && error.errorType && error.errorType === "authen") {
    history.push("dang-nhap");
  }
  ShowNotification(
    viVN.Error[
      error && error.errorType ? error.errorType : "UnableHandleException"
    ],
    NotificationMessageType.Error
  );
}

const FileExtensionsPreviewSupport = [
  "png",
  "jpg",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "pdf",
];

export function FilePreviewSupport(fileType) {
  if (!fileType) return false;
  return FileExtensionsPreviewSupport.some(
    (fileExt) => fileExt.toUpperCase() == fileType.toUpperCase()
  );
}

export const FILE_EXTENSION_ICON = {
  DOC: "doc",
  DOCX: "docx",
  PPT: "ppt",
  PDF: "pdf",
  XLSX: "xlsx",
  TIF: "tif",
  DWG: "dwg",
  DNG: "dng",
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
  XLS: "xls",
  PPTX: "pptx",
  TXT: "txt",
};

export function GenerateDeviceId() {
  const cookies = new Cookies();
  var uid = cookies.get("DeviceId");
  if (uid == null) {
    const expireTime = new Date();
    expireTime.setTime(expireTime.getTime() + 30 * 24 * 60 * 60 * 1000);

    let options = { path: "/", domain: domainName, expires: expireTime };
    cookies.set("DeviceId", uniqueID(), options);
    return uniqueID();
  }
  else {
    return uid;
  }
  function uniqueID() {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() + chr4() + chr4();
  }
}
