import MapDataModels from "../models/map-data-model-b/map-data-model";
import LayerSettingsModels from "../models/map-data-model-b/layer-setting-models";
import LayerCategoryModels from "../models/map-data-model-b/layer-category-models";
import BaseMapModels from "../models/map-data-model-b/base-map-models";

export const ExtracAllLayersArrays = (
  dataToggleLayer: LayerCategoryModels.LayerCategoryModel[]
): LayerSettingsModels.LayerSettingsModel[] => {
  let result: LayerSettingsModels.LayerSettingsModel[] = [];
  dataToggleLayer.map(mapSetting => {
    mapSetting.layer_settings.map(layerSetting => {
      result.push(layerSetting);
      layerSetting.layerRealationships.map((child) =>{
        result.push(child)
      })
    })
  })
  return result;
};


export const ExtracLayerListGroup = (
  mapData: MapDataModels.MapDataModels
): LayerCategoryModels.LayerCategoryModel[] => {
  let result: LayerCategoryModels.LayerCategoryModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      result = mapSetting.layer_categories;
      return;
    }
  });
  return result;
};

export const ExtractListLayerArray = (
  mapData: MapDataModels.MapDataModels
): LayerSettingsModels.LayerSettingsModel[] => {
  const ListLayer: LayerSettingsModels.LayerSettingsModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      mapSetting.layer_categories.map((layerCategory) => {
        layerCategory?.layer_settings?.map((layerSetting) =>{
          ListLayer.push(layerSetting)
          layerSetting?.layerRealationships?.map((child) =>{
            ListLayer.push(child)
          }
        )
        }
        );
      });
      return;
    }
  });
  return ListLayer;
};

export const ExtractExtentArray = (
  mapData: MapDataModels.MapDataModels
): [number, number, number, number] => {
  const extent: [number, number, number, number] = [0, 0, 0, 0];
  mapData.extent
    .split(",")
    .map((numberValue, index) => (extent[index] = Number(numberValue)));
  return extent;
};
export const ExtractCenterArray = (
  mapData: MapDataModels.MapDataModels
): number[] => {
  const center: number[] = [];
  mapData.center
    .split(",")
    .map((numberValue) => center.push(Number(numberValue)));
  return center;
};

export const ExtractBasemapList = (
  mapData: MapDataModels.MapDataModels
): BaseMapModels.BaseMapModel[] => {
  let result: BaseMapModels.BaseMapModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      result = mapSetting.base_maps;
      return;
    }
  });
  return result;
};

export const ExtractSelectedBasemap = (
  mapData: MapDataModels.MapDataModels
): string => {
  let baseMapUrl = "";
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      mapSetting.base_maps.map((basemap) => {
        if (basemap.view_default) baseMapUrl = basemap.url;
        return;
      });
      return;
    }
  });
  return baseMapUrl;
};
