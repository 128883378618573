/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../core/app.store";
import * as newsActions from "../../redux/store/news/news.store";

import {
  APIUrlDefault,
  DomainUserSide,
} from "../../utils/configuration";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./detail.scss";

import dateformat, { i18n } from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";


function NewsDetail(props) {
  const { showLoading } = props;

  i18n.dayNames = [
    "Chủ nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
    "Chủ nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
  ];
  
  i18n.monthNames = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "11",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "11",
  ];

  const [newsModel, setNewsModel] = useState([]);
  const [newsDetail, setNewsDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pagingNews, setPagingNews] = useState();
  const pageSize = 10;
  const {id} = props.match.params;

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListNews({
        pageIndex: 1,
        pageSize: pageSize
      }),
      onGetNewsDetail(id),
    ])
        .then((res) => {
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

  const onGetNewsDetail = (id) => {
    return new Promise((resolve,reject) => {
      newsActions.GetDetailNews(id).then(
          (res) => {
            setNewsDetail(
                res && res.content
            );
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );

    })
  }

  const onGetListNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListNews(data).then(
          (res) => {
            setNewsModel(
                res &&
                res.content &&
                res.content.items
                && res.content.items.length > 0 ? res.content.items: []
            );
            setPagingNews(res.content);
            setCurrentPage(res.content.pageIndex);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );
    });
  }

  const getNextPage = () => {
    let nextPage = 1;
    if(currentPage >0 ){
      nextPage = currentPage + 1;
    }
    onGetListNews({
      pageIndex: nextPage,
      pageSize: pageSize
    })
  }

  const getPreviousPage = () => {
    let prePage = 1;
    if(currentPage >1){
      prePage = currentPage - 1;
    }
    onGetListNews({
      pageIndex: prePage,
      pageSize: pageSize
    })
  }

  return (
      <div>
        <section className="news">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="hot-new">
                  
                  <div className="detail-title">
                    <a className="news-title" href="#">{newsDetail.title}</a>
                  </div>
                  <div className="new-date">
                    {dateformat(newsDetail.created_date, "ddd, dd/mmm/yyyy, HH:MM:ss")}
                  </div>

                  <div className="new-image detail-news-image">
                    <a href="#">
                      <img
                          src={APIUrlDefault + newsDetail.image_Url}
                          onError={(e) =>
                              (e.target.src = require("../../assets/image/noImage.png"))
                          }
                          alt={newsDetail.title}
                      />
                    </a>
                  </div>
                  <p className="new-desc" dangerouslySetInnerHTML={{ __html: newsDetail.description }}></p>
                  <p className="new-content" dangerouslySetInnerHTML={{ __html: newsDetail.content }}></p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="new-nav">
                  {
                    pagingNews && pagingNews.hasPreviousPage && (
                        <a className="mr-3" onClick={getPreviousPage}>
                          <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />
                        </a>
                    )
                  }
                  
                  <b>{pagingNews && pagingNews.pageIndex} / {pagingNews && pagingNews.pageCount}</b>

                  {pagingNews && pagingNews.hasNextPage && (
                      <a className="ml-3" onClick={getNextPage}>
                        <FontAwesomeIcon icon={faArrowRight} className="icon-link" />
                      </a>
                  )}
                </div>

                <div className="other-news">
                  {newsModel && newsModel.length >0 ? (
                      newsModel.filter((item) => item.id != newsDetail.id).map((item,index) => (
                          <div key={index} className="new-item">
                            <div className="new-image">
                              <a href={DomainUserSide + "/tin-tuc-chi-tiet/" + item.id} >
                                <img
                                  className="img-news"
                                  src={APIUrlDefault + item.image_Url}
                                  onError={(e) =>
                                      (e.target.src = require("../../assets/image/noImage.png"))
                                  }
                                  alt={item.title}
                                />
                              </a>
                            </div>
                            <div className="new-info">
                              <a href={"/tin-tuc-chi-tiet/" + item.id}  className="new-title">{item.title}</a>
                              <div className="new-date">{item.createDate}</div>
                              <a href={"/tin-tuc-chi-tiet/" + item.id} className="view-detail">
                                Xem thêm
                                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0.842842 7.83347L16.029 7.83355L12.0238 11.6689C11.7037 11.9754 11.701 12.4812 12.0178 12.7911C12.3347 13.1009 12.8491 13.1036 13.1692 12.7971L18.5779 7.61782C18.898 7.3113 18.9007 6.81345 18.5838 6.50358L13.2382 1.25963C12.9214 0.94976 12.4069 0.947047 12.0869 1.25356C11.7668 1.56008 11.7641 2.05793 12.081 2.3678L16.0373 6.25307L0.851179 6.25298C0.40206 6.25061 0.0327228 6.60428 0.0304302 7.03892C0.0281376 7.47355 0.393723 7.8311 0.842842 7.83347Z"
                                      fill="#1075BD"/>
                                </svg>
                              </a>
                            </div>
                          </div>
                      ))
                  ):(
                      <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(NewsDetail));
