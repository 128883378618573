import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './preview.scss';

function Preview(props) {
    const {imageInfo} = props;
    const [previewLabel,setPreviewLabel] = useState("");
    const [imageSrc,setImageSrc] = useState("");
    useEffect(() => {
        if(imageInfo && imageInfo.previewLabel && imageInfo.imageSrc){
            setPreviewLabel(imageInfo.previewLabel);
            setImageSrc(imageInfo.imageSrc);
        }
    }, [imageInfo]);
    return(
        <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            id="previewImage"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                {previewLabel && imageSrc && (
                <div
                    className="modal-content modal-content-custom"
                    style={{ height: `${props.modalHeightStyle}px` }}
                >
                        <div className="preview-content-header">
                            <div className="preview-close-button" data-dismiss="modal" aria-label="Close">
                                <svg width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle"
                                     className="svg-inline--fa fa-times-circle fa-w-16" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                                </svg>
                            </div>
                            <div className="preview-modal-title">{previewLabel}</div>
                        </div>
                        <div className="preview-content-box">
                        <img src={imageSrc} />
                        </div>

                </div>
                )}
            </div>
        </div>
    );

}

const mapStateToProps = (state) => ({
    imageInfo: state.mapData.previewImageInfo,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Preview);