import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetDocumentType = () => {
    return service.get(ApiUrl.DocumentType).then(response => { return response }).catch(error => { throw error });
}

export const SearchDocument = (data) => {
    const params = new URLSearchParams();
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);
    data.name && params.append('name', data.name);
    data.documentTypeId && params.append('documentTypeId', data.documentTypeId);
    data.district && params.append('district', data.district);
    data.year && params.append('year', data.year);
    data.planningUnitId && params.append('planningUnitId', data.planningUnitId);
    data.planningTypeId && params.append('planningTypeId', data.planning);
    data.field && params.append('field', data.field);
    return service.get(ApiUrl.SearchDocument, params).then(response => { return response }).catch(error => { throw error });
}
export const GetLockUpDistrict = () => {
    return service.get(ApiUrl.GetCmsLookupDistrict).then(response => { return response }).catch(error => { throw error });
}
export const GetLookUpPlanningUnit = () => {
    return service.get(ApiUrl.GetLookUpPlanningUnit).then(response => { return response }).catch(error => { throw error });
}
export const GetLookupCommune = () => {
    return service.get(ApiUrl.GetLookupCommune).then(response => { return response }).catch(error => { throw error });
}
export const GetDocumentByPlanningId = (id) => {
    const params = new URLSearchParams();
    params.append('planningId', id);
    return service.get(ApiUrl.GetDocumentByPlanningId, params).then(response => { return response }).catch(error => { throw error });
}
export const GetChildrenDocument = (planningId, parentId) => {
    const params = new URLSearchParams();
    params.append('planningId', planningId);
    params.append('parentId', parentId);
    return service.get(ApiUrl.GetChildrenDocument, params).then(response => { return response }).catch(error => { throw error });
}
export const GetYearStatement = () => {
    return service.get(ApiUrl.GetYearStatement).then(response => { return response }).catch(error => { throw error });
}
export const DownloadFile = (id) => {
    const params = new URLSearchParams();
    params.set('id', id);
    return service.getBinary(ApiUrl.DownloadFile, params).then(response => { return response }).catch(error => { throw error });
}