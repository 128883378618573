import React, { useEffect, useState } from "react";
import "./look-up-planning.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SearchByDistrict } from "./child-modules/planning-district/search-district.view";
import SearchByAddress from "./child-modules/search-address/search-address.view";
import PlanningByTypeView from "./child-modules/planning-type/planning-type.view";
import PlanningByDistrictView from "./child-modules/planning-district/planning-district.view";
import UrlCollect from "../../common/url-collect";
import SearchPlanningView from "./child-modules/search-planning-name/search-planning-name.view";
import * as statementActions from "../../redux/store/statement/statement.store";
import history from "../../common/history";
import * as mapHelper from "../../common/map";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import uongBiCoordinate from "../../common/uongbi_coordinate";
import { GetVn2000ToWgs84, GetLatLngPlace } from "../../redux/store/home-page/home-page.store";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import Select from "react-select";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as appActions from "../../core/app.store";
import * as documentAction from "../../redux/store/document/document.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

function LookUpPlanningView(props) {
  const { showLoading} = props;
  const [planningType, setPlanningType] = useState([]);
  const [dataSelectBoxSearch, setDataSelectBoxSearch] = useState([]);
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(true);
  const [listDistrict, setListDistrict] = useState([]);
  const [districtSelect, setDistrictSelect] = useState(new URLSearchParams(props.location.search).get("district") ?
    new URLSearchParams(props.location.search).get("district").split(',') : []);
  const [name, setName] = useState(new URLSearchParams(props.location.search).get("name") ?
    new URLSearchParams(props.location.search).get("name") : '');
  const [planningTypeId, setPlanningTypeId] = useState(new URLSearchParams(props.location.search).get("key") ?
  new URLSearchParams(props.location.search).get("key") : '');


  let commentInput = null;

  useEffect(() => {
    getAllPlanningType();
    GetLookupDistrict();
  }, []);

  useEffect(() => {
    showLoading(true);
    setDistrictSelect(new URLSearchParams(window.location.search).get("district") ?
    new URLSearchParams(window.location.search).get("district").split(',') : [])
    setName(new URLSearchParams(window.location.search).get("name") || '')
    setPlanningTypeId(new URLSearchParams(window.location.search).get("key") || '')
    console.log(new URLSearchParams(window.location.search).get("district"))
    showLoading(false);
  }, [new URLSearchParams(window.location.search).get("key"),
  new URLSearchParams(window.location.search).get("district"),
  new URLSearchParams(window.location.search).get("name")])

  const getAllPlanningType = () => {
    statementActions
      .PlanningType()
      .then((res) => {
        if (res && res.content) {
          setPlanningType(res.content);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const GetLookupDistrict = () => {
    return new Promise((resolve, reject) => {
      documentAction
        .GetLockUpDistrict()
        .then((res) => {
          setListDistrict(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return { ...item, label: item.name, value: item.id };
              })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  let PATHNAME =
    "?key=" + new URLSearchParams(props.location.search).get("key");

  function createClassListLeftSelectMenu(pathName) {
    return PATHNAME === pathName ? "p-2 active-custom" : "p-2";
  }

  function handleSearchCoordinate(event) {
    event.preventDefault();
    let value = commentInput.value.trim();
    let coordinateList = value.split(",");
    if (coordinateList && coordinateList.length === 2) {
      let x = parseFloat(coordinateList[0]);
      let y = parseFloat(coordinateList[1]);

      GetVn2000ToWgs84(x, y).then((res) => {
        console.log(res)
        let isInside = false;
        props.listPolygon &&
          props.listPolygon.map((itemPolygon) => {
            if (mapHelper.isPointInPolygon(res[1], res[0], itemPolygon)) {
              isInside = true;
              return;
            }
            if (isInside) {
              history.push(
                `${UrlCollect.PlanningMapViewDetail}2?lng=${x}&lat=${y}&searchType=codinate`
              );
            } else {
              ShowNotification(
                viVN.Errors.CoordinateOutSidePolygon,
                NotificationMessageType.Warning,
                NotificationPosition.Center
              );
            }
          });

      },
        (err) => {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      )
    }
  }

  const handleSelectAddress = (address) => {
    GetLatLngPlace(address.label)
      .then((res) => res.result[0])
      .then((result) => result.location)
      .then((latLng) => {
        let isInside = false;
        props.listPolygon &&
          props.listPolygon.map((itemPolygon, index) => {
            if (
              mapHelper.isPointInPolygon(latLng.lat, latLng.lng, itemPolygon)
            ) {
              isInside = true;
              return;
            }
          });
        if (isInside) {
          history.push(
            `${UrlCollect.PlanningMapViewDetail}2?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`
          );
        } else {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      })
      .catch();
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const Options = async (search, loadedOptions) => {
    if (search.length >= 4) {
      GetLatLngPlace(search)
        .then((res) => {
          setFilteredSuggestions(res && res.result && res.result.length > 0 ? res.result : [])
        })
        .catch()
    }

    const addressSuggestions = filteredSuggestions.map((item, index) => {
      return ({
        value: index,
        label: item.address
      })
    })
    return {
      options: addressSuggestions,
    }
  }
  const onSubmit = () => {
    
    history.push(`${UrlCollect.PlanningMap}?key=${planningTypeId}&name=${name}&district=${districtSelect.join(',')}`)
  };



  return (
    <div className="container look-up-planning-container">
      <div className="row">
        <div className="col-12 col-xl-3 left-menu">
          <SearchByDistrict
            params={props}
            onSubmit={onSubmit}
            setDistrictSelect={data => setDistrictSelect(data)}
            name={name}
            districtSelect={districtSelect}
            setName={(data) => setName(data)}
            isLookup={true}
          />
          {/* <div className="search-planning">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group mb-3 mt-3">
              <input
                type="text"
                name="name"
                ref={register}
                className="form-control"
                placeholder="Tìm kiếm Dự án"
                aria-label="Tìm kiếm Dự án"
                aria-describedby="searchFile"
              />
            </div>

            {listDistrict && listDistrict.length > 0 && (
              <Select
                className="basic-single mb-1"
                classNamePrefix="select"
                placeholder="Huyện / Tp"
                name="district"
                isMulti
                onChange={handleOnChangeDistrict}
                options={listDistrict}
                noOptionsMessage={() => "không tồn tại"}
              />
            )}

            <div className="input-group mb-3 mt-3">
              <button
                type="submit"
                className="text-uppercase btn btn-info w-100"
              >
                <FontAwesomeIcon icon={faSearch} className="mr-1" />
                Tìm kiếm
              </button>
            </div>
          </form>
          </div> */}

          {/* <SearchPlanningView
            dataSelectBoxSearch={dataSelectBoxSearch}
            setSelected={setSelected}
          />
          {show ? (
            <div className="row search-container no-gutters align-items-center">
              <div className="col-10">
              <AsyncPaginate
                className="react-select-container  select-search"
                //classNamePrefix="react-select"
                cacheOptions
                defaultMenuIsOpen={true}
                defaultOptions
                loadOptions={Options}
                onChange={handleSelectAddress}
                placeholder="Nhập địa điểm tra cứu"
                noOptionsMessage={() => "Không có dữ liệu"}
              />
              </div>
              <div className="col-2 text-right">
                <button
                  className="bg-transparent border-0"
                  onClick={() => setShow(false)}
                >
                  <img
                    src={require("../../assets/icon/planning-name.svg")}
                    alt="Planning Name"
                  />
                </button>
              </div>
            </div>
          ) : (
              <div className="row search-container no-gutters align-items-center">
                <div className="col-10">
                  <form onSubmit={handleSearchCoordinate}>
                    <input
                      type="text"
                      ref={(input) => {
                        commentInput = input;
                      }}
                      className="form-control"
                      placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                    />
                  </form>
                </div>
                <div className="col-2 text-right">
                  <button
                    className="bg-transparent border-0"
                    onClick={() => setShow(true)}
                  >
                    <img
                      src={require("../../assets/icon/place.svg")}
                      alt="Planning Name"
                    />
                  </button>
                </div>
              </div>
            )}
          <SearchByDistrict/> */}
          <div className="list-select-side-menu">
            <Link
              key={"SyntheticPlanning"}
              to={UrlCollect.SyntheticPlanning}
              className={createClassListLeftSelectMenu(
                UrlCollect.SyntheticPlanning
              )}
            >
              Tổng hợp quy hoạch
            </Link>
            {planningType &&
              planningType.length > 0 &&
              planningType.map((item, index) => (
                <Link
                  key={index}
                  to={UrlCollect.PlanningMap + "?key=" + item.id + '&name=' + name + '&district=' + districtSelect}
                  className={createClassListLeftSelectMenu("?key=" + item.id)}
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </div>
        <div className="col-md-9 col-12">
          {/* {new URLSearchParams(props.location.search).get("key") === null ? (
            <PlanningByDistrictView
              params={props}
              setDataSelectBoxSearch={setDataSelectBoxSearch}
              selected={selected}
            />
          ) : (
            <PlanningByTypeView
              params={props}
              setDataSelectBoxSearch={setDataSelectBoxSearch}
              selected={selected}
            />
          )} */}
          <PlanningByDistrictView
            params={props}
            setDataSelectBoxSearch={setDataSelectBoxSearch}
            selected={selected}
            planningTypeId={planningTypeId}
            name={name}
            districtSelect={districtSelect}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LookUpPlanningView)
