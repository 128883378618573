export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_information: "Thông tin",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_reflect: "Phản ánh hiện trường",
  header_map: "Bản đồ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_cityPlanning: "Quy hoạch theo Huyện/Thành phố",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  user_info: "Thông tin cá nhân",
  reflect_recommendations: "Phản ánh kiến nghị",
  header_myReflect: "Phản ánh của bạn",
  change_password: "Đổi mật khẩu",
  header_account: "Tài khoản",
  header_title: "GIS Tiên Yên",
  footer_titleName: "BAN QLDA ĐTXD KHU VỰC HUYỆN THANH THỦY",
    footer_email: "bbtthanhthuy.phutho@gmail.com",
    footer_email_to: "bbtthanhthuy.phutho@gmail.com",
    footer_address: "Thị trấn Thanh Thủy, huyện Thanh Thủy , tỉnh Phú Thọ",
    footer_hotline: "",
    footer_hotline_tel: "",
    footer_fax: "",
    footer_website: "https://thanhthuy.phutho.gov.vn",
    footer_website_to: "https://thanhthuy.phutho.gov.vn", 
    contact_map_center_lat: 20.827501724727288,
    contact_map_center_lng: 105.34172903977286,
    contact_map_marker_lat: 20.827501724727288,
    contact_map_marker_lng: 105.34172903977286,
    contact_map_url: 'https://www.google.com/maps/embed/v1/place?q=thị+trấn+Thanh+Thủy,+huyện+Thanh+Thủy,+tỉnh+Phú+Thọ,+Vietnam&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8',
};
