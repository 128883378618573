import React, { useState, useEffect } from 'react';
import '../planning-type/planning-type.scss';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationView from '../../../../components/pagination/pagination.view.jsx';
import ThumbnailPreview from '../planning-type/child/thumbnail-preview.view';

import * as statementActions from '../../../../redux/store/statement/statement.store';
import * as config from '../../../../utils/configuration';
import * as appActions from "../../../../core/app.store";
import * as homePageStore from "../../../../redux/store/home-page/home-page.store";
import PageUrls from '../../../../common/url-collect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function PlanningByDistrictView(props) {
  const { isLoading, showLoading, params, setDataSelectBoxSearch, selected, planningTypeId } = props;
  const [planningDetail, setPlanningDetail] = useState();
  useEffect(() => {

   
    const district = new URLSearchParams(params.location.search).get("district");
    const planningTypeId = new URLSearchParams(params.location.search).get("key");
    const name = new URLSearchParams(params.location.search).get("name");

    getPlanningSearchByIdName(planningTypeId,name,district,1, 9);
  }, [params.location.search])
  

  const getPlanningSearchByIdName = (planningTypeId,name,district,pageIndex = 1,pageSize = 9 ) => {
    showLoading(true);
    return homePageStore.GetPlanningSearchByIdNameDistrict(planningTypeId,name,district,pageIndex,pageSize).then(res => {
      // if (res && res.content) {
      setPlanningDetail(res && res.content ? res.content : {});
      
      // }
      showLoading(false)
    }).catch(err => showLoading(false))
  }

  


  return (
    <div className="planning-by-type-container position-relative">
      <div className="row">
        {planningDetail && planningDetail.items && planningDetail.items.length > 0 ? planningDetail.items.map((item, index) => (
          <ThumbnailPreview key={index} backgroundUrl={item.photo && item.photo !== 'null' ? config.APIUrlDefault + item.photo : require('../../../../assets/image/img-default.png')} previewName={item.name} urlParams={PageUrls.PlanningMapViewDetail + item.id} />
        )) : (
          <div className="col-12 text-center text-danger mb-3 mt-5">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2"
            />{" "}
            Không có quy hoạch nào
          </div>
        )}
      </div>
      <div className="pagination-container list-footer">
        {
          planningDetail && planningDetail.pageCount > 0 && (
            <div className={`row ${planningDetail.pageCount === 1 ? 'justify-content-center' : 'justify-content-between'}`}>
              <div className="text-result">
                <span className="ml-2">Có {planningDetail.totalItemCount} kết quả</span>
              </div>
              {
                planningDetail.pageCount > 1 && (
                  <PaginationView
                    isLookup={true}
                    totalItems={planningDetail.totalItemCount}
                    currentIndex={planningDetail.pageIndex}
                    pageSize={planningDetail.pageSize}
                    onClick={getPlanningSearchByIdName}
                    planningTypeId={new URLSearchParams(params.location.search).get("key")}
                    name={new URLSearchParams(params.location.search).get("name")}
                    district={new URLSearchParams(params.location.search).get("district")}
                  />)
              }
            </div>
          )
        }
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PlanningByDistrictView);