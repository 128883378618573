import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import "./planning-type.scss"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function PlanningType(props) {
    const { showLoading } = props;
    const [generalPlanning, setGeneralPlanning] = useState([]);
    const [detailedPlanning, setDetailedPlanning] = useState([]);
    const [subdivisionPlanning, setSubdivisionPlanning] = useState([]);
    const [specializedPlanning, setSpecializedPlanning] = useState([]);

    useEffect(() => {
        GetPlanning();
    }, [])

    const GetPlanning = () => {
        showLoading(true);
        homePageStore.GetPlanningSearchByIdName(null, 1, null, null, 100)
            .then(res => {
                const generalPlanningTemp = [];
                const detailedPlanningTemp = [];
                const subdivisionPlanningTemp = [];
                const specializedPlanningTemp = [];

                if (res && res.content && res.content.items) {
                    res.content.items.map((item) => {
                        if (item.planningTypeId === 1 && subdivisionPlanningTemp.length < 4) {
                            subdivisionPlanningTemp.push(item);
                        }
                        else if (item.planningTypeId === 2 && generalPlanningTemp.length < 4) {      
                            generalPlanningTemp.push(item);
                        }
                        else if (item.planningTypeId === 3 && detailedPlanningTemp.length < 4) {
                            detailedPlanningTemp.push(item);
                        }
                        else if (item.planningTypeId === 4 && specializedPlanningTemp.length < 4) {
                            specializedPlanningTemp.push(item);
                        }
                    });

                    setGeneralPlanning(generalPlanningTemp);
                    setDetailedPlanning(detailedPlanningTemp);
                    setSubdivisionPlanning(subdivisionPlanningTemp);
                    setSpecializedPlanning(specializedPlanningTemp);
                }
                showLoading(false);
            }).catch(err => {
                showLoading(false);
            });
    }

    return (
        <section style={{backgroundColor: '#EEEEEE'}}>
            <div className="planning_section container">
                <div className="p-3">
                    {/* <Link to='/tong-hop-quy-hoach'>
                        <h3>Tổng hợp quy hoạch</h3>
                    </Link> */}
                    <div className="row home_planning">
                        <div className="col-lg-6">
                            <div className="planning_type_name">
                                <p>Quy hoạch chung</p>
                                <Link to="/ban-do-quy-hoach?key=2">{`Xem thêm >>`}</Link>
                            </div>
                            <div className="planning_type_items">
                                {generalPlanning && generalPlanning.length > 0 ?
                                    <>
                                        {generalPlanning.map((item, index) => (
                                            <div className="planning_type_item" key={index}>
                                                <Link to={`/ban-do-quy-hoach/${item.id}`}><FontAwesomeIcon icon={faSquare} color="#fb0505fa" /> {item.name}</Link>
                                            </div>
                                        ))}
                                    </> : <>
                                    <div className="planning_type_item">
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="#fb0505fa" /> Không có quy hoạch nào
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                        
                        <div className="col-lg-6">
                            <div className="planning_type_name">
                                <p>Quy hoạch phân khu</p>
                                <Link to="/ban-do-quy-hoach?key=1">{`Xem thêm >>`}</Link>
                            </div>
                            <div className="planning_type_items">
                                {subdivisionPlanning && subdivisionPlanning.length > 0 ?
                                    <>
                                        {subdivisionPlanning.map((item, index) => (
                                            <div className="planning_type_item" key={index}>
                                                <Link to={`/ban-do-quy-hoach/${item.id}`}><FontAwesomeIcon icon={faSquare} color="#fb0505fa" /> {item.name}</Link>
                                            </div>
                                        ))}
                                    </> : <>
                                    <div className="planning_type_item">
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="#fb0505fa" /> Không có quy hoạch nào
                                    </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="planning_type_name">
                                <p>Quy hoạch chi tiết</p>
                                <Link to="/ban-do-quy-hoach?key=3">{`Xem thêm >>`}</Link>
                            </div>
                            <div className="planning_type_items">
                                {detailedPlanning && detailedPlanning.length > 0 ?
                                    <>
                                        {detailedPlanning.map((item, index) => (
                                            <div className="planning_type_item" key={index}>
                                                <Link to={`/ban-do-quy-hoach/${item.id}`}><FontAwesomeIcon icon={faSquare} color="#fb0505fa" /> {item.name}</Link>
                                            </div>
                                        ))}
                                    </> : <>
                                    <div className="planning_type_item">
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="#fb0505fa" /> Không có quy hoạch nào
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="planning_type_name">
                                <p>Quy hoạch phân khu chức năng</p>
                                <Link to="/ban-do-quy-hoach?key=4">{`Xem thêm >>`}</Link>
                            </div>
                            <div className="planning_type_items">
                                {specializedPlanning && specializedPlanning.length > 0 ?
                                    <>
                                        {specializedPlanning.map((item, index) => (
                                            <div className="planning_type_item" key={index}>
                                                <Link to={`/ban-do-quy-hoach/${item.id}`}><FontAwesomeIcon icon={faSquare} color="#fb0505fa" /> {item.name}</Link>
                                            </div>
                                        ))}
                                    </> : <>
                                    <div className="planning_type_item">
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="#fb0505fa" /> Không có quy hoạch nào
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(PlanningType));